const armenian = {
  hymns: 'Օրհներգեր',
  hymn: 'Օրհներգ',
  version: 'Տարբերակ',
  language: 'arm',
  key: 'arm-hymns',
  noData: 'Տվյալներ չկան',
  regExp: {
    onlyLetters: '[^ա-ֆԱ-Ֆ]'
  },

  navItems: {
    hymns_1_800: 'Օրհներգեր 1-800',
    alphabeticalIndex: 'Այբբենական ցուցիչ',
    content: 'Բովանդակություն',
    history: 'Պատմություն',
    bookmark: 'Էջանիշեր',
    preface: 'Նախաբան',
    reference: 'Տեղեկություն',
    AboutTheApp: 'Ծրագրի մասին',
    settings: 'Կարգավորումներ'
  },

  search: {
    searchByArmenianNumber: 'Որոնել ըստ հայերեն համարի',
    searchByEnglishNumber: 'Որոնել ըստ անգլերեն համարի',
    searchByText: 'Որոնել ըստ տեքստի',
    search: 'Որոնել',
    errorAlert: 'համապատասխան օրհներգը չի գտնվել!'
  },

  appBar: {
    copyAlert: 'Այս օրհներգի հղումը պատճենվել է.'
  },

  about: {
    overview:
      'Այս հավելվածը պաշտոնական էլեկտրոնային տարբերակն է օրհներգերի ժողովածուի, որը հրապարակվել է «Աստվածաշնչյան գրքի հավաքիչ»-ի կողմից 2014 թվականին: Այս ժողովածուում ներառված օրհներգերը ընտրված են մի քանի օրհներգերի ժողովածուներից և այլ գրքերից, որոնք թողարկվել են «Կենդանի հոսք» ծառայության կողմից (Անահայմ, ԱՄՆ), և թարգմանվել են անգլերենից։',
    asteriskNote:
      '* Բոլոր օրհներգերը, որոնք նշված են աստղանիշով, սկզբնապես գրվել են անգլերեն Ուիթնես Լիի և «Կենդանի հոսք» ծառայության (ԱՄՆ) խմբագրական բաժնի կողմից։',
    crossNote:
      '† Բոլոր օրհներգերը, որոնք նշված են խաչանիշով, հանդիսանում են տարբեր հեղինակների ստեղծագործություններ, որոնք թարգմանվել են անգլերեն Ուիթնես Լիի և «Կենդանի հոսք» ծառայության (ԱՄՆ) խմբագրական բաժնի կողմից։',
    numbering:
      'Յուրաքանչյուր օրհներգի տեքստից առաջ նշված է օրհներգի չափը և համապատասխան օրհներգի համարը անգլերեն ժողովածուում, որը հրապարակվել է «Կենդանի հոսք» ծառայության կողմից (համարը նշված է փակագծերում):',
    contact: 'Այս հավելվածի հետ կապված բոլոր հարցերի համար, խնդրում ենք գրել հետևյալ հասցեով՝',
    officialSite: 'Պաշտոնական կայք',
    collectorBook: '«Աստվածաշնչյան գրքի հավաքիչ»',
    rightsReserved: 'Բոլոր իրավունքները պաշտպանված են'
  },

  alphabeticalIndex: {
    alphabet: [
      'Ա',
      'Բ',
      'Գ',
      'Դ',
      'Ե',
      'Զ',
      'Է',
      'Թ',
      'Ժ',
      'Ի',
      'Լ',
      'Խ',
      'Ծ',
      'Կ',
      'Հ',
      'Ձ',
      'Ղ',
      'Ճ',
      'Մ',
      'Յ',
      'Ն',
      'Շ',
      'Ո',
      'Չ',
      'Պ',
      'Ջ',
      'Ս',
      'Վ',
      'Տ',
      'Ց',
      'Ու',
      'Փ',
      'Ք',
      'Օ'
    ],
    alphabeticalIndex: 'Այբբենական ցուցիչ'
  },

  history: {
    deleteHistory: 'Ջնջել պատմությունը',
    dialog: 'Իսկապե՞ս ուզում եք ջնջել ձեր ամբողջ պատմությունը?',
    yes: 'այո',
    no: 'ոչ'
  },

  preface: {
    preface:
      'Այստեղ ներկայացված նախաբանը սկզբնապես տպագրվել է «Hymns» ժողովածուում (Living Stream Ministry, Անահայմ, Կալիֆորնիա, ԱՄՆ, 1966 թ.), որը ներառում էր 1080 օրհներգ և որի բովանդակությունը կազմում է այս ռուսերեն ժողովածուի հիմքը: Նախաբանը տպագրվում է փոքր փոփոխություններով:',
    introduction:
      'Քրիստոնեական օրհներգերն ու երգերը սուրբերի սրտերում ձևավորվող հոգևոր զգացմունքների արտահայտությունն են, որոնք արդյունք են նրանց՝ Քրիստոսում ունեցած փորձառությունների, Աստծուն և Նրա ճշմարտությանը ճանաչելու, ինչպես նաև Աստծուն՝ Հորը և Տեր Հիսուս Քրիստոսին ուղղված գոհաբանության, փառաբանության և երկրպագության: Դրանք անհրաժեշտ են և՛ քրիստոնեական հավաքների, և՛ անհատական քրիստոնեական կյանքի համար, որպեսզի դրանց միջոցով հասնեն հետևյալ նպատակներին.',
    goals: [
      'Երկրպագել Աստծուն՝ Հորը',
      'Փառաբանել Տեր Հիսուս Քրիստոսին',
      'Սրբերին խրատել',
      'Եկեղեցին կառուցել',
      'Հոգևոր պատերազմ վարել',
      'Ավետարանը հայտարարել'
    ],
    churchExistence:
      'Եկեղեցին արդեն շուրջ քսան դար գոյություն ունի երկրի վրա: Այդ երկար ժամանակահատվածում բոլոր դարերում բազմաթիվ հրաշալի օրհներգեր և երգեր են ստեղծվել, որոնք գրվել են սրբերի կողմից, ովքեր ունեցել են որոշակի հոգևոր փորձառություններ և գիտելիքներ: Նրանց հետքերով գնալով, մենք ստացել ենք այս օրհներգերի հավաքածուի ժառանգությունը՝ վայելելու այն որպես հարուստ ժառանգություն: Հետևաբար, մենք փորձել ենք այս ժողովածուում հնարավորինս հավաքել բոլոր այդ ստեղծագործությունները, որպեսզի այսօրվա եկեղեցին կարողանա օգտվել և վայելել Աստծո տան ողջ հարստությունը: Մենք երախտապարտ ենք ինչպես նրանց հեղինակներին, այնպես էլ մեր Հորը՝ այն Միակին, ով տալիս է բոլոր պարգևները:',
    inclusion:
      'Մենք փորձել ենք այս ժողովածուում ներառել բոլոր դասական, լավ և առողջ հոգևոր օրհներգերն ու երգերը, բացառությամբ այն մի քանիսի, որոնց նկատմամբ չունեինք հրապարակման իրավունք: Ընտրվել են ավելի քան 700 օրհներգ անցած դարերից: Բացի դրանից, մենք փորձել ենք առանձնացնել որոշ հատվածներ այլ հոգևոր ստեղծագործություններից, որոնք կարող էին վերածվել երաժշտության և երգվել որպես օրհներգեր: Այս աղբյուրներից ընտրվել և մշակվել է մեծ թվով ստեղծագործություններ, և դրանց ընդհանուր թիվը գերազանցում է 800-ը: Մենք հավատում ենք, որ այս ժողովածուն ներառում է բոլոր դարերի քրիստոնեական փորձառությունների և ճշմարտության բարձրագույն մակարդակը:',
    hymnSelection:
      'Օրհներգերի ընտրության ընթացքում տեքստերը մանրակրկիտ ուսումնասիրվել են, և դրանց մեջ մի շարք փոփոխություններ են արվել (բացառությամբ այն դեպքերի, երբ հեղինակային իրավունքները խոչընդոտում էին դրան)՝ ճշմարտության փոխանցման ճշգրտությունն ու հոգևորությունն ավելի բարձրացնելու նպատակով:',
    lordRestoration:
      'Տիրոջ վերականգնման ճանապարհին, եկեղեցին ավելի խորն է ճանաչում Տիրոջը և Նրա ճշմարտությունները: Չնայած անցյալում ստեղծված բազմաթիվ օրհներգերին, դրանց մեծ մասը չի համապատասխանում Տիրոջ վերականգնման ներկայիս մակարդակին:',
    forSatisfaction:
      'Այս կարիքների բավարարումն ապահովելու համար մենք ջանքեր ենք գործադրել թարգմանել վերջին քառասուն տարիների ընթացքում չինարեն գրված որոշ օրհներգեր, ինչպես նաև ստեղծել նոր օրհներգեր, որոնք կլրացնեն բացը: Այսպիսով, մենք վերամշակել ենք 33 օրհներգ և ստեղծել 244 նոր օրհներգ:',
    hymnDivisions:
      'Օրհներգերն ու երգերը բաժանվել են 30 հիմնական խմբերի, որոնք վերնագրված են համապատասխանաբար ճշմարտություններին և հոգևոր փորձառություններին:',
    index:
      'Ժողովածուի վերջում տեղադրված է ցուցակ, որտեղ ներկայացված են բոլոր օրհներգերի առաջին տողերը, ինչպես նաև առաջին տողերը կրկներգերից:',
    melodies:
      'Մենք փորձել ենք ներառել լավագույն մեղեդիները, որոնք կհամապատասխանեն մեր նպատակներին:',
    attention:
      'Հատուկ ուշադրություն է դարձվել այն մեղեդիների ընտրությանը, որոնք կլինեն ամենատարածված և ընդունելի:',
    continuousFlow:
      'Մենք նկատեցինք, որ ամենահարմարը այն է, երբ միայն առաջին տողն է տպագրվում երաժշտական նոտաների կողքին:',
    distractions:
      'Մենք հեռացրել ենք նաև բազմաթիվ մասնագիտական երաժշտական նշումներ, որպեսզի ընթերցողը ավելի քիչ շեղվի: Եվ այս պատճառով հեղինակի անունները նույնպես չեն նշվել:',
    gratitudeToPublishers:
      'Մենք շատ երախտապարտ ենք բազմաթիվ հրատարակիչներին, ովքեր թույլ տվեցին օգտագործել իրենց օրհներգերը:',
    permissions:
      'Բոլոր օրհներգերը, որոնք նշված են խաչանիշով (†) և մեր նոր օրհներգերը, նշված են աստղանիշով (*), կարող են ազատ օգտագործվել:',
    gratitudeToBrothersAndSisters:
      'Մենք պարտավոր ենք շնորհակալություն հայտնել բոլոր եղբայրներին և քույրերին, ովքեր օգնեցին այս մեծ աշխատանքի իրականացմանը:',
    usage:
      'Այս գիրքը պատրաստվել է քրիստոնեական ժողովների կարիքները բավարարելու համար, ինչպես նաև անձնական օգտագործման համար:',
    encouragement:
      'Մենք ցանկանում ենք ոգեշնչել սրբերին, որ ավելի շատ ժամանակ տրամադրեն նոր օրհներգերի ուսուցմանը:',
    authors: 'Կազմողները և հեղինակները',
    names: 'Ուիթնես Լի և խմբագրական բաժին',
    location: 'Լոս Անջելես, ԱՄՆ',
    date: 'հուլիս 1966 թ.'
  },

  reference: {
    search: {
      heading: 'Օրհներգերի որոնում',
      mobileLocation:
        'Որոնման կոճակը (խոշորացույց) բջջային տարբերակում գտնվում է աջ ստորին անկյունում, իսկ դեսկտոպ տարբերակում՝ աջ վերին անկյունում:',
      searchFields:
        'Որոնման էջում կա երեք դաշտ. «Որոնում ըստ ռուսական համարի» (կարող եք մուտքագրել մի քանի համարներ ստորակետով առանձնացված), «Որոնում ըստ անգլերեն համարի» և «Որոնում ըստ տեքստի» (ռուսերենով): Որոնման ժամանակ կետադրությունը հաշվի չի առնվում:'
    },
    navigation: {
      heading: 'Օրհներգերի թերթում',
      hymnNavigation:
        'Օրհներգերի թերթումը համակարգչի էկրանին իրականացվում է էկրանի եզրերին գտնվող սլաքներով: Բջջային սարքի էկրանին օրհներգերը կարելի է թերթել հորիզոնական շարժումով (ժեստով) մատով՝ էկրանի վրայով, բայց լրացուցիչ հնարավոր է միացնել սլաքներով թերթումը (տե՛ս «Կարգավորումներ» բաժինը):'
    },
    mainMenu: {
      heading: 'Հիմնական մենյու',
      openMenu:
        'Հիմնական մենյուն բացվում է պատուհանի ձախ վերին մասում գտնվող կոճակին սեղմելով: Հիմնական մենյուն այնպիսի մի վահանակ է, որը սահում է էկրանի ձախ մասում: Հետագա բոլոր օգնության բաժինները նկարագրում են հիմնական մենյուի պարունակությունը:'
    },
    alphabeticalIndex: {
      heading: 'Այբբենական ցուցանիշ',
      indexDescription:
        'Այբբենական ցուցանիշը պարունակում է առաջին տողերի և կրկներգերի առաջին տողերի ցանկը՝ դասավորված այբբենական կարգով: Կրկներգերի տողերը գրված են մեծատառերով:'
    },
    content: {
      heading: 'Բովանդակություն',
      description:
        'Այս ժողովածուի բոլոր օրհներգերը բաժանված են խորագրերի, որոնք իրենց հերթին բաժանված են ենթաբաժինների: «Բովանդակություն» էջում խորագրերը և ենթաբաժինները բացվում են իերարխիկ կառուցվածքով: Յուրաքանչյուր օրհներգի էջում առաջին տողում նշվում է խորագրի անվանումը, իսկ երկրորդ տողում՝ ենթաբաժնի անվանումը:'
    },
    history: {
      heading: 'Պատմություն',
      description:
        'Օգտագործողի կողմից բացված օրհներգերը պահվում են «Պատմություն» էջում: Բացված օրհներգը պատմության մեջ պահպանվում է 30 վայրկյան դիտելուց հետո: Պատմության մեջ պարունակվում է վերջին 100 օրհներգը:'
    },
    bookmarks: {
      heading: 'Զտիչներ',
      description:
        'Օրհներգերի ցանկը, որոնց ավելացվել են զտիչներ, կարող եք տեսնել «Զտիչներ» էջում: Զտիչ ավելացնելու համար բացեք օրհներգը և սեղմեք հավելվածի վերին վահանակում գտնվող զտիչի պատկերով կոճակը: Զտիչը հեռացվում է նույն կոճակին սեղմելով բացված օրհներգի ժամանակ կամ «Զտիչներ» էջում սեղմելով աղբարկղի պատկերով կոճակը:'
    },
    settings: {
      heading: 'Կարգավորումներ',
      description:
        'Կարգավորումների բաժնում կարելի է կարգավորել տառաչափը, ինչպես նաև միացնել կամ անջատել սլաքներով օրհներգերի թերթումը: Երբ սլաքները միացված են, նաև հնարավոր է թերթել էկրանի վրայով հորիզոնական շարժումով:'
    },
    appUpdate: {
      heading: 'Հավելվածի թարմացում',
      description:
        'Եթե հավելվածը տեղադրված է տեղական սարքի վրա, ապա դրա թարմացման համար անհրաժեշտ է փակել հավելվածը և զննարկիչը (եթե այն բացված է), այնուհետև կրկին բացել հավելվածը: Եթե դուք օգտագործում եք հավելվածը զննարկչի միջոցով, ապա պարզապես անհրաժեշտ է թարմացնել զննարկչի էջը:'
    },
    melodyPlayback: {
      heading: 'Մելոդիաների նվագարկում',
      description:
        'Հավելվածի այս տարբերակում օրհներգերի մելոդիաների նվագարկման գործառույթը չի իրականացված, այն կհայտնվի ավելի ուշ:'
    }
  },

  settings: {
    fontSize: 'Տառատեսակի չափը',
    arrows: 'Սլաքներ',
    off: 'Անջատել',
    on: 'Միացնել',
    searchByEnglishNumber: 'Որոնել ըստ անգլերեն համարի'
  }
};

export default armenian;
