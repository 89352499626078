import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import App from '../App';
import { setAppBarTitle } from '../redux/slice/appBarSlice';
import { AppDispatch, RootState } from '../redux/store';
import { setFontSize, setTitle } from '../utils';
import { useDoubleTap } from '../utils/hooks';
import { AppBar, Drawer } from '../view/components';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFv_OxLXgoolLn0gF0PQAmbEKBOW_rrqQ",
  authDomain: "armenian-hymns.firebaseapp.com",
  projectId: "armenian-hymns",
  storageBucket: "armenian-hymns.appspot.com",
  messagingSenderId: "209713741310",
  appId: "1:209713741310:web:532c821f856816dd0314af",
  measurementId: "G-5WW5WDS60H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

function Layout() {
  const settings = useSelector((state: RootState) => state.settings);
  const currentHymns = useSelector((state: RootState) => state.currentHymns.currentHymns);
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();

  useDoubleTap({ pathname, fontSize: settings.fontSize, dispatch });

  useEffect(() => {
    setFontSize(settings.fontSize);
  }, [settings.fontSize]);

  useEffect(() => {
    const title = setTitle({
      currentHymns,
      pathname,
      lg: settings.language
    });
    title && dispatch(setAppBarTitle(title));
  }, [currentHymns, pathname, dispatch, settings.language]);

  useEffect(() => {
    const item_id = currentHymns.map(el => el.number).join(',');
    const item_name = `Hymn ${item_id}`
    logEvent(analytics, 'hymn_number', {number: item_id});
  }, [currentHymns]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, currentHymns]);

  return (
    <Box sx={{ height: '100%' }}>
      <AppBar lg={settings.language} />
      <Drawer lg={settings.language} dispatch={dispatch} fontSize={settings.fontSize} />
      <Box className="container">
        <App />
      </Box>
    </Box>
  );
}
export default Layout;
